<template>
  <div class="branch-form">
    <v-form class="form" ref="form">
      <v-row>
        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{
            $t("employee_ot.emp_label_name")
          }}</label>

          <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listEmployee" hide-selected
            v-model="employee_id">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.approve_by") }}</label>
          <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listManager" hide-selected
            v-model="approve_by">
            <!-- @change="fetchData" -->
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.approve_by_hr") }}</label>

          <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listHr" hide-selected
            v-model="approve_by_hr">
            <!-- @change="fetchData" -->
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{ $t("employee_ot.date") }}</label>
          <v-btn icon color="primary" @click="dialogAddDate = true">
            <v-icon>mdi-calendar-month</v-icon>
          </v-btn>
          <v-list flat>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in dates" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="`${item.date} (${item.start_time}-${item.end_time})`"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon class="text-danger" @click="delete_date(item.date)">mdi-trash-can</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="8">
          <label class="label-input">{{
            $t("employee_ot.detail")
          }}</label>
          <v-textarea outlined v-model="remark" class="lfont"></v-textarea>
        </v-col>
        <v-col cols="4" md="2"></v-col>

        <v-col cols="4" md="2"></v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="saveChange()">
            {{ $t("employee_ot.edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <modalAddOtDate :dialog="dialogAddDate" @close="(val) => dialogAddDate = val" @add="(val) => add_date(val)" />
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import modalAddOtDate from '@/views/MainCompany/OT/modals/modalAddOtDate';
import Loading from '@/components/Loading';
export default {
  components: {
    DatePicker,
    modalAddOtDate,
    Loading
  },
  data() {
    return {
      isLoading: false,
      dialogAddDate: false,
      listEmployee: [],
      listManager: [],
      listHr: [],
      dates: [],
      employee_id: "",
      employee_ot_id: "",
      approve_by: "",
      approve_by_hr: "",
      remark: "",
      server_errors: {
        employee_id: "",
        date: "",
        check_in: "",
        check_out: "",
        approve_by: "",
        approve_by_hr: "",
      },
    };
  },
  methods: {
    delete_date(date) {
      this.dates = this.dates.filter(item => item.date != date)
    },
    add_date(item) {
      let date = item.date;
      let check = [];
      check = this.dates.filter((item) =>
        item.date.toLowerCase().includes(date.toLowerCase())
      )
      if (check.length == 0)
        this.dates.push(item);
    },
    fetchEmployee() {
      this.$axios.get(`company/all/employee-name`, {}).then((res) => {
        if (res.status === 200) {
          this.listEmployee = res.data.data;
        }
      });
    },
    fetchManager() {
      this.$axios.get(`only/line/managers`, {}).then((res) => {
        if (res.status === 200) {
          this.listManager = res.data.data;
        }
      });
    },
    fetchEmployee_ot_item() {
      this.$axios
        .get(`company/request/ot/${this.employee_ot_id}`)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            this.employee_id = res.data.data.employee_id;
            this.approve_by = res.data.data.approve_by.id;
            this.approve_by_hr = res.data.data.hr_approve ? res.data.data.hr_approve.id : null;
            this.dates = res.data.data.ot_dates;
            this.remark = res.data.data.details;
          }
        });
    },
    fetchHrlist() {
      this.$axios.get(`only/hr`, {}).then((res) => {
        if (res.status === 200) {
          this.listHr = res.data.data;
        }
      });
    },

    saveChange() {
      this.isLoading = true;
      const items = {
        employee_id: this.employee_id,
        dates: this.dates,
        // date: moment(this.date).format("YYYY-MM-DD"),
        approve_by: this.approve_by,
        approve_by_hr: this.approve_by_hr,
        detail: this.remark,
      };
      this.$axios
        .put(`company/request/ot/${this.employee_ot_id}`, items)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.$router
              .push({
                name: "employee_ot.index",
              })
              .catch(() => { });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    calculate_hour() {
      if (this.check_in != "" && this.check_out != "") {
        this.total_hour = moment(
          moment(this.check_out, "hh:mm").diff(moment(this.check_in, "hh:mm"))
        ).format("hh:mm");
        console.log(this.total_hour);
      }
    },
  },
  computed: {
    total_hour: function () {
      if (this.check_in != "" && this.check_out != "") {
        let start = moment(this.check_in, "HH:mm");
        let end = moment(this.check_out, "HH:mm");
        return (end - start) / 60 / 1000;
      }
      return 0;
    },
  },

  created() {
    this.employee_ot_id = this.$route.params.employee_ot_id;
    this.fetchEmployee_ot_item();
    this.fetchEmployee();
    this.fetchManager();
    this.fetchHrlist();
  },
};
</script>

<style lang="scss" scoped>
.branch-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 800px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
